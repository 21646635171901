<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i>删除</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`pack_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getTableData"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import { packAPI } from '@api/modules/pack';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/TransportationManagement/PackManage/pack';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'packList',
  components: {
    SearchTable
  },
  data() {
    return {
      tableProperties: tableProperties,
      loadFlag: true,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {}
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getTableData();
      this.getStaffId();
    },
    async getTableData() {
      await (this.loadFlag = true);
      await getNoCatch(packAPI.getPack, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    getStaffId() {
      getNoCatch(packAPI.getDeptInfo).then(
        ({ data }) =>
          (this.tableProperties[7].options = Array.from(data, ({ stff_id, stff_name }) => {
            return { value: stff_id, label: stff_name };
          }))
      );
    },
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message.info('已取消删除');
          this.$refs.multiTable.clearSelection();
        });
    },
    delRow() {
      let ids = Array.from(this.multiSelection, ({ pack_id }) => pack_id);
      packAPI.deletePackByIds(ids).then(({ data }) => {
        this.$message.success('删除成功!');
        this.initData();
      });
    },
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/pack_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.pack_id
          })
        )
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
